<template>
  <div class="space-bar">
    <div style="font-size: 18px; font-weight: 600">
      <div>
        当前使用空间总容量
        <span v-if="spaceSize.countSize < 1048576" style="color: #1479FF">{{(spaceSize.countSize /1024).toFixed(2)}}MB/{{(spaceSize.userAllSize/1024/1024).toFixed(2)}}GB</span>
        <span v-else style="color: #1479FF">{{(spaceSize.countSize/1024/ 1024).toFixed(2)}}GB/{{(spaceSize.userAllSize/1024/1024).toFixed(2)}}GB</span></div>
    </div>
    <div id="bar" style="width: 100%; height: 100px;"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  name: "spaceBar",
  data() {

  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  props: {
    spaceSize: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  created() {

  },

  mounted() {
    this.getSpaceNum();
  },

  methods: {
    // 获取空间大小
    getSpaceNum() {
      var that = this;
      var myChart = echarts.init(document.getElementById('bar'));
      // 配置图表
      var option = {
        legend: {
          bottom: '10px',
          icon: 'circle',
        },
        title: {
          show: false
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '90%',
        },
        color: ['#F5CD01', '#F66232', '#A575EC', '#E1E1E1'],
        xAxis: {
          type: 'value',
          show: false,
        },
        yAxis: {
          type: 'category',
          show: false,
        },
        series: [
          {
            name: '图片 ' + (that.spaceSize.imagesSpaceSize / 1024 / 1024).toFixed(3) + 'GB',
            type: 'bar',
            barWidth: '50%',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [that.spaceSize.imagesSpaceSize / 1024 / 1024]
          },
          {
            name: '视频 ' + (that.spaceSize.videosSpaceSize / 1024 / 1024).toFixed(3) + 'GB',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [that.spaceSize.videosSpaceSize / 1024 / 1024]
          },
          {
            name: '文件 ' + (that.spaceSize.enclosureSpaceSize / 1024 / 1024).toFixed(3) + 'GB',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [that.spaceSize.enclosureSpaceSize / 1024 / 1024]
          },
          {
            name: '剩余 ' + ((that.spaceSize.userAllSize - that.spaceSize.countSize) / 1024 / 1024).toFixed(3) + 'GB',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [((that.spaceSize.userAllSize - that.spaceSize.countSize) / 1024 / 1024).toFixed(3)]
          }
        ]
      };
      myChart.setOption(option)
    }
  }

}
</script>

<style scoped>

</style>
