<template>
       <div id="app">
            <!-- 网盘空间 -->
                  <div class="space">
                        <div @click="backHome"><svg t="1666946406643" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2063" width="15" height="15"><path d="M224.45 538.68l575.11-346.21-0.05-68.75-575.03 345.61-0.03 69.35z" p-id="2064"></path><path d="M224.45 538.68l575.08 356.96v-69.52L224.48 469.33l-0.03 69.35z" p-id="2065"></path></svg><span style="width: 42px;height: 25px;font-size: 21px;color: #444444;cursor:pointer;margin:0px 3px">返回</span></div>
                        <div class="netdisk">我的空间</div>
                        <el-button v-if="user.userType < 3" type="primary" class="bottom" @click="buy">购买空间容量 ></el-button>
                  </div>
                  <!-- 网盘空间总容量 -->
                  <div class="capacity">
                       <div style="padding: 20px">
                           <SpaceBar></SpaceBar>
                       </div>
                  </div>
            </div>
</template>

<script>

    import SpaceBar from "@/components/common/spaceBar";
    export default {
        components: {
            SpaceBar

        },
      data() {
        return {
             input2:'',
             input3:'',
             value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
             mySpaceSize:[],
             andarticlestype:[],
             articleArr:[],
             common_type_name:{
                "1":"公开",
                "2":"粉丝",
                "3":"好友",
                "4":"私密",
            },
            status:"1",
            andarticle:[]
            }
        },
         computed: {
           user() {
             return this.$store.state.user;
           },
           is_login(){
             return this.$store.state.is_login
           }
        },

        created:function(){

        },
        mounted: function () {
          var that = this;
          that.getMySpaceSize();
          that.getMyEnclosuresAndArticle()
        },

        methods:{
            download1 (url,name){
                this.utils.download(url,name)
            },
             download_type (url,name){
                this.utils.download(url,name)
            },

            getMySpaceSize:function(){
                  var that = this;
                  that.newApi.getMySpaceSize({
                }).then((res)=>{
                  that.mySpaceSize = res.data;
              }).catch((err)=>{
                  console.log(err)
              })
            },
            getMyEnclosuresAndArticle:function(){
                      var that = this;
                      that.newApi.getMyEnclosuresAndArticle({
                    }).then((res)=>{
                      that.andarticle = res.data.enclosureArr;
                      that.andarticlestype =res.data.articleArr;
                  }).catch((err)=>{
                      console.log(err)
                  })
            },

            // 返回个人空间首页
            backHome() {
                this.$emit('checkoutHome', 'normal')
            },
            open() {
                this.$message({
                message: '编辑附件还在开发中',
                type: 'warning'
             });
            },
            openve (){
               this.$message({
                message: '编辑文章还在开发中',
                type: 'warning'
             });
            },
            // 跳转到购买空间
            buy(){
                this.$router.push("/member")
            }
        },

    }
</script>

<style scoped>
   * {
      margin: 0;padding: 0;
   }
    .personal {
      margin-left: 200px;
    }
   .return {
      width: 44px;
      height: 26px;
      font-size: 22px;
   }
   .space {
      width: 1153px;
      height: 57px;
      background: #FFFFFF;
      box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
      border-radius: 5px 5px 0px 0px;

      float: left;
      display:flex;

   }
   .icon {
      margin-top:19px;
      margin-left: 53px;
      cursor:pointer
   }
   .netdisk {
      width: 96px;
      height: 28px;
      font-size: 24px;
      color: #444444;
      float: left;
      margin-left: 411px;
      margin-top:7px;
      cursor:pointer;
   }
   .bottom {
      width: 177px;
      height: 37px;
      background: #1479FF;
      border-radius: 5px 5px 5px 5px;
      margin-left: 320px;
      margin-top:7px;
   }
   .capacity {
      width: 1153px;
      height: 160px;
      background: #FFFFFF;
      box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
      float: left;
      margin-top: 7px;
   }
    .capacity .capacity-it {
      width: 165px;
      height: 24px;
      font-size: 20px;
      color: #444444;
      font-weight: 400;
      padding:30px 54px 0;
      float: left;
   }
   .capacity .uesd {
      width: 280px;
      height: 24px;
      font-size: 20px;
      color: #444444;
      float: left;
      padding:32px 0 0;
      margin-left: 600px;

   }
   .memory {
      width: 1049px;
      height: 16px;
      background: #E1E1E1;
      float: left;
      margin:10px 56px 0;
   }
   .yellow {
      height: 16px;
      background: #F5CD01;
      width: 16px;
      border-radius: 50%;
      float: left;
      margin:18px 0 0 54px;
   }
   .Red {
      height: 16px;
      background: #F66232;
      width: 16px;
      border-radius: 50%;
      float: left;
      margin:18px 0 0 54px;
   }
   .purple {
      height: 16px;
      background: #A575EC;
      width: 16px;
      border-radius: 50%;
      float: left;
      margin:18px 0 0 54px;
   }
   .pink {
      height: 16px;
      background: #F771AE;
      width: 16px;
      border-radius: 50%;
      float: left;
      margin:18px 0 0 54px;
   }
   .time {
      width: 1153px;
      height: 50px;
      background: #FFFFFF;
      box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
      float: left;
      margin-top: 10px;
   }
   .time_list {
      width: 112px;
      height: 19px;
      font-size: 16px;
      font-weight: 400;
      color: #555555;
      margin:16px 0 0 15px;
      float: left;
   }
   .year {
      border-radius: 5px 5px 5px 5px;
      float: left;
      margin-top:5px;
   }
   .attachment {
      width: 570px;
      height: 64px;
      background: #FFFFFF;
      box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
      float: left;
      margin-top:10px;
   }
   .article {
      width: 570px;
      height: 64px;
      background: #FFFFFF;
      box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
      float: left;
      margin: 10px 0 0 13px;
   }
   .catch {
      margin:0 0 0 22px;
   }
  /deep/ .el-input__inner {
    background: #F0F2F5;
   }
   /deep/ .el-date-editor .el-range-input {
    background: #F0F2F5;
   }
   .title{
        overflow: hidden;
        overflow-y: scroll;
        max-height: 300px;
        line-height: 26px;
    }
    .title::-webkit-scrollbar {
        display: none;
    }
</style>
